@font-face {
  font-family: materialBold;
  src: url('/assets/fonts/Poppins/Poppins-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: materialSemiBold;
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.woff2');
  font-display: swap;
}

@font-face {
  font-family: materialMedium;
  src: url('/assets/fonts/Poppins/Poppins-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: materialLight;
  src: url('/assets/fonts/Poppins/Poppins-Light.woff2');
  font-display: swap;
}

@font-face {
  font-family: materialRegular;
  src: url('/assets/fonts/Poppins/Poppins-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: materialBold;
  src: url('/assets/fonts/Almarai/Almarai-Bold.woff2');
  unicode-range: U+0600-06FF;
  font-display: swap;
}

@font-face {
  font-family: materialSemiBold;
  src: url('/assets/fonts/Almarai/Almarai-Bold.woff2');
  unicode-range: U+0600-06FF;
  font-display: swap;
}

@font-face {
  font-family: materialMedium;
  src: url('/assets/fonts/Almarai/Almarai-Medium.woff2');
  unicode-range: U+0600-06FF;
  font-display: swap;
}

@font-face {
  font-family: materialLight;
  src: url('/assets/fonts/Almarai/Almarai-Light.woff2');
  unicode-range: U+0600-06FF;
  font-display: swap;
}

@font-face {
  font-family: materialRegular;
  src: url('/assets/fonts/Almarai/Almarai-Regular.woff2');
  unicode-range: U+0600-06FF;
  font-display: swap;
}
